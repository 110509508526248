<template>
    <label class="form-roup row">
        <div class="col-md-2 col-form-label">
            <slot name="label-text">
                {{ label }}
            </slot>
        </div>
        <div class="col-md-10">
            <input
                v-if="readonly"
                v-model="localValue"
                readonly
                type="text"
                class="form-control"
            >
            <input
                v-else
                v-model="localValue"
                type="text"
                :class="`${error != '' ? 'is-invalid' : ''} form-control`"
            >
            <div
                v-if="error != ''"
                class="invalid-feedback"
            >
                {{ error }}
            </div>
        </div>
    </label>
</template>

<script>
export default {
    props: {
        readonly: {
            type: Boolean,
            required: false,
            default: () => false
        },
        label: {
            type: String,
            required: false,
            default: () => ''
        },
        modelValue: {
            type: [String, Number],
            required: false,
            default: () => ''
        },
        value: {
            type: String,
            required: false,
            default: () => ''
        },
        error: {
            type: String,
            required: false,
            default: () => ''
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            localValue: this.modelValue == '' ? this.value : this.modelValue
        }
    },

    watch: {
        localValue(newVal) {
            this.$emit('update:modelValue', newVal);
        },

        modelValue(newVal) {
            this.localValue = newVal;
        },
        
        value(newVal) {
            this.localValue = newVal;
        }
    }
}
</script>