<template>
    <loading-layer :is-loading="buffer.isLoading" />

    <form
        :loading="buffer.isLoading"
    >
        <div class="d-flex mb-3">
            <p class="h6 font-weight-bold">
                {{ __('MENUBAR.GENERAL_DATA') }}
            </p>
        </div>
        <div
            v-if="buffer.errorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.errorMessage }}
        </div>
        <div
            v-if="buffer.criticalErrorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.criticalErrorMessage }}
        </div>
        
        <div v-if="buffer.criticalErrorMessage == ''">
            <input-text-row
                v-model.trim="company.companyName"
                :readonly="true"
                :label="__('LABELS.FISCAL_NAME')"
            />

            <input-text-row
                v-model="company.numWorkers"
                :readonly="true"
                :label="__('LABELS.NUM_WORKERS')"
            />

            <input-text-row
                v-if="company.nif"
                v-model.trim="company.nif"
                :readonly="true"
                :label="__('LABELS.NIF')"
            />

            <input-text-row
                v-model.trim="company.address"
                :readonly="true"
                :label="__('LABELS.ADDRESS')"
            />

            <input-text-row
                :value="company.countryDescription"
                :readonly="true"
                :label="__('LABELS.COUNTRY')"
            />

            <input-text-row
                v-model.trim="company.zipCode"
                :readonly="true"
                :label="__('LABELS.ZIP')"
            />

            <input-text-row
                v-model.trim="company.city"
                :readonly="true"
                :label="__('LABELS.CITY')"
            />
        </div>
    </form>
</template>

<script>
import InputTextRow from '../../components/input-text_row.vue';
import LoadingLayer from '../../components/loading_layer.vue';

import TooltipStrings from '../../lang/tooltips.json';

import __ from '../../utils/translator.js';

import Constants from '../../Constants.js';
import { getCountryName, getLegalSizeName } from '../../db/db-utils.js';

export default {

    components: {
        InputTextRow,
        LoadingLayer
    },

    inject: ['GLOBAL_COUNTRIES', 'GLOBAL_LEGALSIZES', 'COMPANY'],

    data() {
        return {
            company: this.COMPANY.value,

            tooltips: {
                legalSize: TooltipStrings.eng.legalSize
            },

            buffer: {
                Constants,

                isLoading: false,
                updateSuccess: false,
                criticalErrorMessage: '',
                errorMessage: '',

                countries: this.GLOBAL_COUNTRIES,
                legalSizes: this.GLOBAL_LEGALSIZES
            }
        }
    },

    methods: {
        getCountryName,
        getLegalSizeName,
        __ //translator
    }
};
</script>